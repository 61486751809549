import useApi from '~/composables/useApi';

export async function fetchChannelEpg(cid) {
  const { data: epgData, fetch: fetchEpg } = useApiData('v3/program/listall', {
    query: { cid },
    transform: d => d.programs,
  });
  await fetchEpg();
  return epgData.value;
}

export function fetchChannelUrl(options) {
  return useApi(
    `/channel/url/`,
    { query: options },
  );
}

// not used methods:
// transform Array To Object (camelCase)
export async function getChannelEpg(cid) {
  const epg = await fetchChannelEpg(cid);
  const ms = 1000;
  let channelEPG = [];
  for (const p of epg) {
    channelEPG.push({
      titleId: p[0],
      programName: p[1],
      beginTime: p[2] * ms,
      endTime: p[3] * ms,
      ageRating: p[4],
      categories: [5].join(', ').trim(),
      // poster: p[5] || '',
      // bigImage: p[6] || '',
    });
  }
  return channelEPG;
}

export function fetchProgramUrl(options) {
  return useApi(
    `/channel/record_url/`,
    { query: options },
  );
}

export function fetchProgramFullInfo(options) {
  return useApi(
    `/program/fullinfo/`,
    { query: options },
  );
}

export function fetchProgramSeries(options) {
  return useApi(
    `/program/seriesinfo/`,
    { query: options },
  );
}
